// eslint-disable-next-line no-restricted-imports
import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import { Connector } from '@web3-react/types'
import IconWallet from 'assets/images/icon-wallet.png'
import { ButtonSecondary } from 'components/Button'
import StatusIcon from 'components/Identicon/StatusIcon'
import Loader from 'components/Loader'
import { RowBetween } from 'components/Row'
import WalletModal from 'components/WalletModal'
import { coinbaseWallet, getWalletForConnector } from 'connectors'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { darken } from 'polished'
import { useMemo, useRef } from 'react'
import { Activity } from 'react-feather'
import { useHistory } from 'react-router-dom'
import { useModalOpen, useToggleModal, useWalletModalToggle } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { isTransactionRecent, useAllTransactions } from 'state/transactions/hooks'
import { TransactionDetails } from 'state/transactions/types'
import { updateSelectedWallet, updateUserInfo } from 'state/user/reducer'
import styled from 'styled-components/macro'
import { MEDIA_WIDTHS } from 'theme'
import { shortenAddress } from 'utils'
import { isChainAllowed } from 'utils/switchChain'

const IconWrapper = styled.div<{ size?: number }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  & > * {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
`

const Web3StatusGeneric = styled(ButtonSecondary)`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  align-items: center;
  padding: 0.5rem;
  border-radius: 14px;
  cursor: pointer;
  user-select: none;
  height: 36px;
  margin-right: 2px;
  margin-left: 1px;
`
const Web3StatusError = styled(Web3StatusGeneric)`
  background-color: ${({ theme }) => theme.red1};
  border: 1px solid ${({ theme }) => theme.red1};
  color: ${({ theme }) => theme.white};
  font-weight: 500;
  :hover,
  :focus {
    background-color: ${({ theme }) => darken(0.1, theme.red1)};
  }
`

// const Web3StatusConnect = styled(Web3StatusGeneric)<{ faded?: boolean }>`
//   background-color: transparent;
//   border: none;

//   font-weight: 500;
//   :focus {
//     border: 1px solid #dfdfdf;
//   }

//   :hover {
//     background-color: #dfdfdf;
//     color: #222222;
//     border: 1px solid #dfdfdf;
//   }

//   ${({ faded }) =>
//     faded &&
//     css`
//       background-color: transparent;
//       border: 1px solid #dfdfdf;

//       :hover {
//         background-color: #dfdfdf;
//         color: #222222;
//         border: 1px solid #dfdfdf;
//       }
//     `}
// `

const Web3StatusConnected = styled(Web3StatusGeneric)<{ pending?: boolean }>`
  background-color: ${({ pending, theme }) => (pending ? theme.bg1 : theme.bg1)};
  border: 1px solid #dfdfdf;
  font-weight: 500;

  p {
    color: #e7e7e7;
  }
  :hover,
  :focus {
    background-color: #dfdfdf;
    p {
      color: #222;
    }
  }
`

const Text = styled.p`
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 0.5rem 0 0.25rem;
  font-size: 1rem;
  width: 100%;
  font-weight: 500;
`

const NetworkIcon = styled(Activity)`
  margin-left: 0.25rem;
  margin-right: 0.5rem;
  width: 16px;
  height: 16px;
`

// we want the latest one to come first, so return negative if a is after b
function newTransactionsFirst(a: TransactionDetails, b: TransactionDetails) {
  return b.addedTime - a.addedTime
}

function WrappedStatusIcon({ connector }: { connector: Connector }) {
  return (
    <IconWrapper size={16}>
      <StatusIcon connector={connector} />
    </IconWrapper>
  )
}
const FlyoutHeader = styled.div`
  color: #e7e7e7;
  font-weight: 400;
  cursor: pointer;
  width: 100%;
`
const FlyoutMenu = styled.div`
  position: absolute;
  top: 54px;
  width: fit-content;
  z-index: 99;
  @media screen and (min-width: ${MEDIA_WIDTHS.upToSmall}px) {
    top: 40px;
  }
`
const FlyoutMenuContents = styled.div`
  align-items: flex-start;

  margin: 0 0.5rem 0 0.25rem;
  background-color: ${({ theme }) => theme.bg0};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  overflow: auto;
  padding: 16px 3.5rem 16px 3.5rem;
  & > *:not(:last-child) {
    margin-bottom: 12px;
  }
`

function Web3StatusInner() {
  const { account, connector, chainId, ENSName } = useWeb3React()

  const error = useAppSelector((state) => state.wallet.errorByWallet[getWalletForConnector(connector)])

  const chainAllowed = chainId && isChainAllowed(connector, chainId)

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)

  const hasPendingTransactions = !!pending.length
  const toggleWalletModal = useWalletModalToggle()

  const history = useHistory()
  const dispatch = useAppDispatch()
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.PROFILE_MENU)
  const toggle = useToggleModal(ApplicationModal.PROFILE_MENU)
  useOnClickOutside(node, open ? toggle : undefined)

  if (!chainId) {
    return (
      <div onClick={toggleWalletModal} className="link-block w-inline-block">
        <img src={IconWallet} loading="lazy" alt="" className="image-11" />
      </div>
    )
  } else if (!chainAllowed) {
    return (
      <Web3StatusError onClick={toggleWalletModal}>
        <NetworkIcon />
        <Text>
          <Trans>Wrong Network</Trans>
        </Text>
      </Web3StatusError>
    )
  } else if (error) {
    return (
      <Web3StatusError onClick={toggleWalletModal}>
        <NetworkIcon />
        <Text>
          <Trans>Error</Trans>
        </Text>
      </Web3StatusError>
    )
  } else if (account) {
    return (
      <Web3StatusConnected
        onClick={() => {
          // if (open) toggle()
          // toggleWalletModal()
          toggle()
        }}
        pending={hasPendingTransactions}
        ref={node as any}
        // onMouseEnter={toggle}
        // onMouseLeave={() => {
        //   console.log('onMouseLeave')
        //   if (open) toggle()
        // }}
      >
        {hasPendingTransactions ? (
          <RowBetween>
            <Text>{pending?.length} Pending</Text> <Loader stroke="white" />
          </RowBetween>
        ) : (
          <Text>{ENSName || shortenAddress(account)}</Text>
        )}
        {!hasPendingTransactions && connector && <WrappedStatusIcon connector={connector} />}
        {open && (
          <FlyoutMenu>
            <FlyoutMenuContents>
              <FlyoutHeader
                onClick={(event) => {
                  if (open) toggle()
                  event.stopPropagation()
                  if (connector.deactivate) {
                    connector.deactivate()

                    // Coinbase Wallet SDK does not emit a disconnect event to the provider,
                    // which is what web3-react uses to reset state. As a workaround we manually
                    // reset state.
                    if (connector === coinbaseWallet) {
                      connector.resetState()
                    }
                  } else {
                    connector.resetState()
                  }

                  dispatch(updateSelectedWallet({ wallet: undefined }))
                  dispatch(updateUserInfo({ userInfo: null }))
                  history.replace('/login')
                }}
              >
                <Trans>Logout</Trans>
              </FlyoutHeader>
            </FlyoutMenuContents>
          </FlyoutMenu>
        )}
      </Web3StatusConnected>
    )
  } else {
    return (
      <div onClick={toggleWalletModal} className="link-block w-inline-block">
        <img src={IconWallet} loading="lazy" alt="" className="image-11" />
      </div>
    )
  }
}

export default function Web3Status() {
  const { ENSName } = useWeb3React()

  const allTransactions = useAllTransactions()

  const sortedRecentTransactions = useMemo(() => {
    const txs = Object.values(allTransactions)
    return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
  }, [allTransactions])

  const pending = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)
  const confirmed = sortedRecentTransactions.filter((tx) => tx.receipt).map((tx) => tx.hash)

  return (
    <>
      <Web3StatusInner />
      <WalletModal ENSName={ENSName ?? undefined} pendingTransactions={pending} confirmedTransactions={confirmed} />
    </>
  )
}
