/* eslint-disable no-restricted-imports */
import 'react-datepicker/dist/react-datepicker.css'
import 'antd/dist/antd.css'
import 'styles/normalize.css'
import 'styles/webflow.css'
import 'styles/custom-design.css'

import { useWeb3React } from '@web3-react/core'
import { Layout, message } from 'antd'
import { useAnalyticsReporter } from 'components/Analytics'
import CustomFooter from 'components/CustomFooter'
import CustomHeader from 'components/CustomHeader'
import ErrorBoundary from 'components/ErrorBoundary'
import Loader from 'components/Loader'
import Popups from 'components/Popups'
import TopLevelModals from 'components/TopLevelModals'
import { coinbaseWallet, getWalletForConnector } from 'connectors'
import { SupportedChainId } from 'constants/chains'
import ApeModeQueryParamReader from 'hooks/useApeModeQueryParamReader'
import Dashboard from 'pages/Dashboard'
import { RedirectPathToHomeOnly } from 'pages/Dashboard/redirects'
import Login from 'pages/Login'
import { Suspense, useCallback } from 'react'
import { useEffect } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'
import { useAddPopup } from 'state/application/hooks'
import { updateLogout } from 'state/application/reducer'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { updateSelectedWallet, updateUserInfo } from 'state/user/reducer'
import { updateWalletError } from 'state/wallet/reducer'
import DarkModeQueryParamReader from 'theme/DarkModeQueryParamReader'
import { isChainAllowed, switchChain } from 'utils/switchChain'

const { Footer, Content } = Layout

export default function App() {
  const history = useHistory()
  useAnalyticsReporter(useLocation())
  const { account, provider, connector, chainId } = useWeb3React()
  const currentUserInfo = useAppSelector((state) => state.user.userInfo)
  const logoutAction = useAppSelector((state) => state.application.logout)
  const dispatch = useAppDispatch()
  const addPopup = useAddPopup()

  const logout = useCallback(() => {
    if (connector && connector.deactivate) {
      connector.deactivate()

      // Coinbase Wallet SDK does not emit a disconnect event to the provider,
      // which is what web3-react uses to reset state. As a workaround we manually
      // reset state.
      if (connector === coinbaseWallet) {
        connector.resetState()
      }
    } else {
      connector.resetState()
    }
    dispatch(updateSelectedWallet({ wallet: undefined }))
    dispatch(updateUserInfo({ userInfo: null }))
    history.replace('/login')
  }, [connector, dispatch, history])

  useEffect(() => {
    if (logoutAction) {
      dispatch(updateLogout({ logout: false }))

      message.error('get unauthorized error')
      logout()
    }
  }, [dispatch, logout, logoutAction])

  const checkNetwork = useCallback(() => {
    if (connector && !(chainId && isChainAllowed(connector, chainId))) {
      const wallet = getWalletForConnector(connector)
      console.log(currentUserInfo?.chainId, typeof currentUserInfo?.chainId)
      const targetChain = currentUserInfo?.chainId ?? SupportedChainId.MAINNET
      dispatch(updateWalletError({ wallet, error: undefined }))
      switchChain(connector, targetChain).catch((error) => {
        dispatch(updateWalletError({ wallet, error: error.message }))
        message.error('Failed to switch networks')
        logout()
      })
    }
  }, [chainId, connector, currentUserInfo?.chainId, dispatch, logout])

  useEffect(() => {
    if (history.location.pathname !== '/login') {
      if (
        currentUserInfo &&
        account &&
        currentUserInfo?.account === account &&
        chainId &&
        currentUserInfo?.chainId === chainId
      ) {
        console.log('the same account')
        if (currentUserInfo.accessExpire > new Date().getTime() / 1000) {
          console.log('no Expire')
          checkNetwork()
          return
        }
      } else if (!provider && !account) {
        history.replace('/login')
      } else {
        if (provider && account && chainId) {
          if (currentUserInfo && currentUserInfo?.account === account && currentUserInfo?.chainId === chainId) {
            console.log('the same account')
            if (currentUserInfo.accessExpire > new Date().getTime() / 1000) {
              console.log('no Expire')

              checkNetwork()
              return
            }
          }
        }
        logout()
      }
    } else {
      if (
        currentUserInfo &&
        account &&
        currentUserInfo?.account === account &&
        chainId &&
        currentUserInfo?.chainId === chainId
      ) {
        console.log('the same account')
        if (currentUserInfo.accessExpire > new Date().getTime() / 1000) {
          console.log('no Expire')
          history.replace('/')
        }
      }
    }
  }, [account, addPopup, chainId, checkNetwork, connector, currentUserInfo, dispatch, history, logout, provider])
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    })
    return () => {
      unlisten()
    }
  }, [history])

  return (
    <ErrorBoundary>
      <Route component={DarkModeQueryParamReader} />
      <Route component={ApeModeQueryParamReader} />
      <Popups />
      <TopLevelModals />
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route exact strict path="/login" component={Login} />
          <Layout>
            <div
              data-animation="over-right"
              data-collapse="medium"
              data-duration="400"
              data-easing="ease"
              data-easing2="ease"
              role="banner"
              className="navbar w-nav"
            >
              <CustomHeader></CustomHeader>
            </div>
            <Layout style={{ flex: 1, minHeight: '80vh' }}>
              <Content>
                <Route exact strict path="/" component={Dashboard} />
                <Route component={RedirectPathToHomeOnly} />
              </Content>
            </Layout>
            <Footer>
              <CustomFooter></CustomFooter>
            </Footer>
          </Layout>
        </Switch>
      </Suspense>
    </ErrorBoundary>
  )
}
