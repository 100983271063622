import { SupportedChainId } from './chains'

const PUBLIC_URL: Record<number, string> = {
  [SupportedChainId.MAINNET]: 'https://www.aw3c.xyz',
  [SupportedChainId.RINKEBY]: 'https://test.aw3c.xyz'
}

const CLOUD_NAME: Record<number, string> = {
  [SupportedChainId.MAINNET]: 'aw3c',
  [SupportedChainId.RINKEBY]: 'aw3c-dev'
}

export const PublicUrl = (chainId: number) => {
  return PUBLIC_URL[chainId] ?? 'https://test.aw3c.xyz'
}

export const GetCloudName = (chainId: number) => {
  return CLOUD_NAME[chainId] ?? 'aw3c-dev'
}
