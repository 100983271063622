import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import { message } from 'antd'
import { ReactComponent as Close } from 'assets/svg/x.svg'
import { AutoColumn } from 'components/Column'
import Modal from 'components/Modal'
import { getWalletForConnector } from 'connectors'
import { CHAIN_INFO } from 'constants/chainInfo'
import { ALL_SUPPORTED_CHAIN_IDS, SupportedChainId } from 'constants/chains'
import { useCallback, useState } from 'react'
import { useModalOpen, useToggleModal } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { useAppDispatch } from 'state/hooks'
import { updateWalletError } from 'state/wallet/reducer'
import styled from 'styled-components/macro'
import { switchChain } from 'utils/switchChain'

import Option from './Option'
import PendingView from './PendingView'

const CloseIcon = styled.div`
  position: absolute;
  right: 1rem;
  top: 14px;
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
`

const CloseColor = styled(Close)`
  path {
    stroke: ${({ theme }) => theme.text4};
  }
`

const Wrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 0;
  width: 100%;
`

const HeaderRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  padding: 1rem 1rem;
  font-weight: 500;
  color: ${(props) => (props.color === 'blue' ? ({ theme }) => theme.primary1 : 'inherit')};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 1rem;
  `};
`

const ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.bg0};
  padding: 0 1rem 1rem 1rem;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  ${({ theme }) => theme.mediaWidth.upToMedium`padding: 0 1rem 1rem 1rem`};
`

const UpperSection = styled.div`
  position: relative;
  h5 {
    margin: 0;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
  }
  h5:last-child {
    margin-bottom: 0px;
  }
  h4 {
    margin-top: 0;
    font-weight: 500;
  }
`

const OptionGrid = styled.div`
  display: grid;
  grid-gap: 10px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: 1fr;
    grid-gap: 10px;
  `};
`

const HoverText = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.text1};
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`

const NETWORK_VIEWS = {
  OPTIONS: 'options',
  PENDING: 'pending'
}

export default function NetworkModal({ onSwitched }: { onSwitched?: () => void }) {
  const [walletView] = useState(NETWORK_VIEWS.OPTIONS)
  const { connector } = useWeb3React()
  const dispatch = useAppDispatch()

  const walletModalOpen = useModalOpen(ApplicationModal.NETWORK_SELECTOR)
  const toggle = useToggleModal(ApplicationModal.NETWORK_SELECTOR)

  const tryActivation = useCallback(
    async (targetChain: number) => {
      if (!connector) return

      const wallet = getWalletForConnector(connector)

      try {
        dispatch(updateWalletError({ wallet, error: undefined }))
        await switchChain(connector, targetChain)
        toggle()
        if (onSwitched) onSwitched()
      } catch (error) {
        console.error('Failed to switch networks', error)
        message.error('Failed to switch networks')

        dispatch(updateWalletError({ wallet, error: error.message }))
      }
    },
    [connector, dispatch, onSwitched, toggle]
  )

  function getOptions() {
    return ALL_SUPPORTED_CHAIN_IDS.map((chainId: SupportedChainId) => {
      const option = CHAIN_INFO[chainId]

      const optionProps = {
        id: `network-${chainId}`,
        header: option.label,
        chainId,
        color: option.color,
        icon: option.logoUrl
      }

      // return rest of options
      return (
        <Option
          {...optionProps}
          key={chainId}
          onClick={() => {
            tryActivation(chainId)
          }}
        />
      )
    })
  }

  function getModalContent() {
    let headerRow
    if (walletView === NETWORK_VIEWS.PENDING) {
      headerRow = null
    } else {
      headerRow = (
        <HeaderRow>
          <HoverText>
            <Trans>Select a network</Trans>
          </HoverText>
        </HeaderRow>
      )
    }

    return (
      <UpperSection>
        <CloseIcon onClick={toggle}>
          <CloseColor />
        </CloseIcon>
        {headerRow}
        <ContentWrapper>
          <AutoColumn gap="16px">
            {walletView === NETWORK_VIEWS.PENDING && <PendingView />}
            {walletView !== NETWORK_VIEWS.PENDING && <OptionGrid data-testid="option-grid">{getOptions()}</OptionGrid>}
          </AutoColumn>
        </ContentWrapper>
      </UpperSection>
    )
  }

  return (
    <Modal isOpen={walletModalOpen} onDismiss={toggle} minHeight={false} maxHeight={90}>
      <Wrapper>{getModalContent()}</Wrapper>
    </Modal>
  )
}
