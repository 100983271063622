import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
`

export const Content = styled.div`
  flex: 1;
  padding: 32px 0;
`
