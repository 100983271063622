import Loader from 'components/Loader'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const PendingSection = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  width: 100%;
  & > * {
    width: 100%;
  }
`

const LoaderContainer = styled.div`
  margin: 16px 0;
  ${({ theme }) => theme.flexRowNoWrap};
  align-items: center;
  justify-content: center;
`

const LoadingMessage = styled.div`
  ${({ theme }) => theme.flexRowNoWrap};
  align-items: center;
  justify-content: center;
  border-radius: 12px;

  & > * {
    padding: 1rem;
  }
`

const LoadingWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
`

export default function PendingView() {
  return (
    <PendingSection>
      <LoadingMessage>
        <LoadingWrapper>
          <ThemedText.Black fontSize={20} marginY={16}>
            <LoaderContainer>
              <Loader stroke="currentColor" size="32px" />
            </LoaderContainer>
            Changing...
          </ThemedText.Black>
        </LoadingWrapper>
      </LoadingMessage>
    </PendingSection>
  )
}
