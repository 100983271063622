import {
  ProFormDateTimePicker,
  ProFormDigit,
  ProFormSelect,
  ProFormText,
  ProFormTextArea,
  StepsForm
} from '@ant-design/pro-components'
import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useGetRejectListMutation } from 'state/daos/slice'

export type FormValueType = {
  target?: string
  template?: string
  type?: string
  time?: string
  frequency?: string
} & Partial<API.RuleListItem>

export type UpdateFormProps = {
  onCancel: (flag?: boolean, formVals?: FormValueType) => void
  onSubmit: (values: FormValueType) => Promise<void>
  updateModalVisible: boolean
  values: Partial<API.RuleListItem>
}

const UpdateForm: React.FC<UpdateFormProps> = (props: UpdateFormProps) => {
  const [getRejectList] = useGetRejectListMutation()
  const [rejectInfo, setRejectInfo] = useState({})
  const [actionType, setActionType] = useState(props.values.status ?? '0')
  useEffect(() => {
    getRejectList().then((data: any) => {
      if (data && data?.data && data.code === 0) {
        const reject = [] as any[]

        data.data.forEach((item: { id: number; description: string; message: string }) => {
          reject[item.id] = item.description
        })
        setRejectInfo({ ...reject })
      }
    })
  }, [getRejectList])
  return (
    <StepsForm
      stepsProps={{
        size: 'small'
      }}
      stepsFormRender={(dom, submitter) => {
        return (
          <Modal
            width={640}
            bodyStyle={{ padding: '32px 40px 48px' }}
            destroyOnClose
            title={'Status'}
            visible={props.updateModalVisible}
            footer={submitter}
            onCancel={() => {
              props.onCancel()
            }}
          >
            {dom}
          </Modal>
        )
      }}
      onFinish={props.onSubmit}
    >
      <StepsForm.StepForm
        initialValues={{
          id: props.values.id,
          status: props.values.status,
          collectionName: props.values.collectionName,
          compressedUrl: props.values.compressedUrl,
          creator: props.values.creator,
          description: props.values.description,
          email: props.values.email,
          engine: props.values.engine,
          socialLink: props.values.socialLink,
          website: props.values.website,
          creatorAddress: props.values.creatorAddress
        }}
        title={'information'}
      >
        <ProFormText name="id" label={'id'} width="md" disabled />
        <ProFormText name="creatorAddress" label={'creatorAddress'} width="md" disabled />
        <ProFormText name="collectionName" label={'name'} width="md" disabled />
        <ProFormTextArea name="description" label={'description'} width="md" disabled />
        <ProFormText name="creator" label={'creator'} width="md" disabled />
        <ProFormText name="engine" label={'engine'} width="md" disabled />
        <ProFormSelect
          name="status"
          width="md"
          label={'Status'}
          fieldProps={{
            onChange: (e) => {
              console.log(e)
              setActionType(e)
            }
          }}
          valueEnum={{
            2: 'Approve',
            3: 'Reject'
          }}
        />
        {rejectInfo && actionType === '3' && (
          <ProFormSelect name="rejectId" width="md" label={'Reject info'} valueEnum={rejectInfo} />
        )}
        {actionType === '2' && (
          <>
            <ProFormDigit
              name="price"
              label={'price'}
              width="md"
              min={0}
              max={22}
              required
              fieldProps={{ precision: 6 }}
            />
            <ProFormDateTimePicker name="mintTime" label={'mintTime'} required width="md" />
          </>
        )}
      </StepsForm.StepForm>
    </StepsForm>
  )
}

export default UpdateForm
