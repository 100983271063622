import { useWeb3React } from '@web3-react/core'
import Web3Status from 'components/Web3Status'
import { useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

export default function CustomHeader() {
  const location = useLocation()
  const history = useHistory()
  const { account } = useWeb3React()
  const active = useMemo(() => {
    return location.pathname
  }, [location.pathname])

  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <>
      <div className="container-3 w-container">
        <div
          onClick={() => {
            history.push('/')
          }}
          aria-current="page"
          className="brand w-nav-brand w--current"
        >
          <h2 className="heading-2">
            <span className="text-span-22">aw3c</span>
            <br />
            <span className="text-span-20"> A.I art on the blockchain</span>
          </h2>
        </div>
        <nav role="navigation" className="nav-menu w-nav-menu">
          <div
            onClick={() => {
              history.push('/')
            }}
            className={`nav-link w-nav-link ${active === '/' ? 'w--current' : ''}`}
          >
            Dashboard
          </div>
          <div className={`nav-link-3 w-nav-link ${account ? 'w--contected' : ''}`}>
            <Web3Status />
          </div>
        </nav>
        <div
          className={`menu-button w-nav-button ${menuOpen ? 'w--open' : ''}`}
          onClick={() => {
            setMenuOpen(!menuOpen)
          }}
        >
          <div className="icon w-icon-nav-menu"></div>
        </div>
      </div>
      {menuOpen && (
        <div
          className="w-nav-overlay"
          onClick={() => {
            setMenuOpen(!menuOpen)
          }}
        >
          <nav role="navigation" className="nav-menu w-nav-menu" data-nav-menu-open>
            <div
              onClick={() => {
                history.push('/')
              }}
              className={`nav-link w-nav-link w--nav-link-open`}
            >
              Dashboard
            </div>
            {/* <div className={`nav-link-3 w-nav-link w--nav-link-open`}> */}
            <Web3Status />
            {/* </div> */}
          </nav>
        </div>
      )}
    </>
  )
}
