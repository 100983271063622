import type { TransactionResponse } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import type { BigNumberish } from 'ethers/lib/ethers'
import { useCallback } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import { calculateGasMargin } from 'utils/calculateGasMargin'

import { useFactoryContract } from './useContract'

export function useSafeMintCallback(): {
  mintCallback: (
    projectId: number,
    name: string,
    symbol: string,
    baseTokenURI: string,
    creator: string,
    creatorAddress: string,
    description: string,
    license: string,
    engine: string,
    recipients: string[],
    basisPoints: BigNumberish[],
    maxSupply: BigNumberish,
    price: BigNumberish,
    mintTime: BigNumberish
  ) => undefined | Promise<TransactionResponse>
} {
  const { account, chainId } = useWeb3React()
  const contract = useFactoryContract(true)

  const addTransaction = useTransactionAdder()

  const mintCallback = useCallback(
    (
      projectId: number,
      name: string,
      symbol: string,
      baseTokenURI: string,
      creator: string,
      creatorAddress: string,
      description: string,
      license: string,
      engine: string,
      recipients: string[],
      basisPoints: BigNumberish[],
      maxSupply: BigNumberish,
      price: BigNumberish,
      mintTime: BigNumberish
    ) => {
      console.log(chainId, account)
      const params = {
        name,
        symbol,
        baseTokenURI,
        creator,
        creatorAddress,
        description,
        license,
        engine,
        recipients,
        basisPoints,
        maxSupply,
        price,
        mintTime
      }
      console.log(params)
      if (!chainId || !account) return undefined
      if (!contract) throw new Error('No Factory Contract!')
      return contract.estimateGas.build(params).then((estimatedGasLimit) => {
        return contract
          .build(params, {
            gasLimit: calculateGasMargin(estimatedGasLimit)
          })
          .then((response: TransactionResponse) => {
            console.log('call response', response)
            addTransaction(response, {
              type: TransactionType.BUILD,
              projectId,
              projectIndex: response.hash,
              launchTime: parseInt(mintTime.toString())
            })
            return response
          })
      })
    },
    [chainId, account, contract, addTransaction]
  )
  return { mintCallback }
}
