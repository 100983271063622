import { LoginForm } from '@ant-design/pro-components'
import { useWeb3React } from '@web3-react/core'
import Logo from 'assets/images/logo.png'
import CustomFooter from 'components/CustomFooter'
import NetworkModal from 'components/NetworkModal'
import Web3Status from 'components/Web3Status'
import React, { useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import uuid from 'react-uuid'
import { useAddPopup, useModalOpen, useToggleModal, useWalletModalToggle } from 'state/application/hooks'
import { ApplicationModal } from 'state/application/reducer'
import { useLoginBySignedMutation } from 'state/daos/slice'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { updateUserInfo } from 'state/user/reducer'
import { isChainAllowed } from 'utils/switchChain'

import * as S from './styles'

export default function Login() {
  const { account, provider, chainId, connector } = useWeb3React()
  const history = useHistory()
  const dispatch = useAppDispatch()
  const addPopup = useAddPopup()
  const toggleWalletModal = useWalletModalToggle()
  const toggleNetworkSelector = useToggleModal(ApplicationModal.NETWORK_SELECTOR)
  const currentUserInfo = useAppSelector((state) => state.user.userInfo)

  const walletModalOpen = useModalOpen(ApplicationModal.WALLET)

  const [loginBySigned] = useLoginBySignedMutation()

  const login = useCallback(() => {
    if (provider && account) {
      const message = `Welcome to AW3C!\n\nClick to sign in and accept the AW3C Terms of Service: https://aw3c.xyz\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nYour authentication status will reset after 24 hours.\n\nWallet address:${account}\n\nNonce:\n${uuid()}`
      provider
        .getSigner(account)
        .signMessage(message)
        .then((signature) => {
          loginBySigned({ address: account, message, signature })
            .then((response: any) => {
              console.log('login response', { ...response.data.data, account, chainId })
              if (response?.data.code === 0) {
                dispatch(updateUserInfo({ userInfo: { ...response.data.data, account, chainId } }))
                if (walletModalOpen) {
                  toggleWalletModal()
                }
                history.replace('/')
              } else {
                addPopup({ error: 'Login failed. Please retry.' })
              }
            })
            .catch(() => {
              addPopup({ error: 'Login failed. Please retry.' })
            })
        })
        .catch((err) => {
          addPopup({ error: 'Login failed. Please retry.' })
        })
    }
  }, [account, addPopup, chainId, dispatch, history, loginBySigned, provider, toggleWalletModal, walletModalOpen])

  const handleSubmit = useCallback(() => {
    if (account && provider) {
      if (!isChainAllowed(connector, chainId ?? 0)) {
        toggleNetworkSelector()
      } else {
        login()
      }
    }
  }, [account, chainId, connector, login, provider, toggleNetworkSelector])

  useEffect(() => {
    if (account && !currentUserInfo) {
      handleSubmit()
    } else if (chainId && !currentUserInfo) login()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, chainId])

  return (
    <S.Container>
      <S.Content>
        <LoginForm
          logo={<img alt="logo" src={Logo} />}
          title="Aw3c"
          subTitle={'Login'}
          initialValues={{
            autoLogin: true
          }}
          actions={[]}
          onFinish={async () => {
            if (account) {
              handleSubmit()
            } else {
              toggleWalletModal()
            }
          }}
        />
      </S.Content>

      <div style={{ display: 'none' }}>
        <Web3Status />
      </div>
      <NetworkModal />
      <CustomFooter />
    </S.Container>
  )
}
