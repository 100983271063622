import {
  ProFormDateTimePicker,
  ProFormDigit,
  ProFormGroup,
  ProFormList,
  ProFormText,
  ProFormTextArea,
  StepsForm
} from '@ant-design/pro-components'
import { Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { useGetProjectInfoMutation } from 'state/daos/slice'

export type FormValueType = {
  target?: string
  template?: string
  type?: string
  time?: string
  frequency?: string
} & Partial<API.RuleListItem>

export type BuildFormProps = {
  onCancel: (flag?: boolean, formVals?: FormValueType) => void
  onSubmit: (values: FormValueType) => Promise<void>
  modalVisible: boolean
  values: Partial<API.RuleListItem>
}

const BuildForm: React.FC<BuildFormProps> = (props: BuildFormProps) => {
  const [getProjectInfo] = useGetProjectInfoMutation()
  const [data, setData] = useState({} as any)

  useEffect(() => {
    if (props?.values?.id) {
      getProjectInfo({ projectId: props.values.id }).then((ret: any) => {
        if (ret?.data?.code === 0) setData(ret.data)
      })
    }
  }, [getProjectInfo, props.values.id])
  return (
    <StepsForm
      stepsProps={{
        size: 'small'
      }}
      stepsFormRender={(dom, submitter) => {
        return (
          <Modal
            width={840}
            bodyStyle={{ padding: '32px 40px 48px' }}
            destroyOnClose
            title={'Status'}
            visible={props.modalVisible}
            footer={submitter}
            onCancel={() => {
              setData({})
              props.onCancel()
            }}
          >
            {dom}
          </Modal>
        )
      }}
      onFinish={async (values) => {
        setData({})
        props.onSubmit(values)
      }}
    >
      <StepsForm.StepForm
        initialValues={{
          id: props.values.id,
          status: props.values.status,
          baseurl: props.values.baseurl,
          collectionName: props.values.collectionName,
          compressedUrl: props.values.compressedUrl,
          creator: props.values.creator,
          description: props.values.description,
          email: props.values.email,
          engine: props.values.engine,
          projectIndex: props.values.projectIndex,
          socialLink: props.values.socialLink,
          website: props.values.website,
          license: 'https://creativecommons.org/licenses/by-nc-nd/4.0/',
          creatorAddress: props.values.creatorAddress,
          price: props.values.price,
          mintTime: props.values.launchTime * 1000,
          maxSupply: props.values.maxSupply
        }}
      >
        <ProFormText name="id" label={'id'} width="md" disabled />

        <ProFormText name="creatorAddress" label={'creatorAddress'} width="md" disabled />
        <ProFormText name="collectionName" label={'name'} width="md" disabled />
        <ProFormTextArea name="description" label={'description'} width="md" disabled />
        <ProFormText name="baseurl" label={'baseurl'} width="md" disabled />
        <ProFormText name="creator" label={'creator'} width="md" disabled />
        <ProFormText name="engine" label={'engine'} width="md" disabled />
        <ProFormText name="license" label={'license'} width="md" disabled />
        <ProFormDigit name="price" label={'price'} width="md" min={0} max={22} fieldProps={{ precision: 6 }} disabled />
        <ProFormDigit
          fieldProps={{ value: data && data.data && data.data.maxSupply >= 0 ? data.data.maxSupply : 'loading' }}
          name="maxSupply"
          label={'maxSupply'}
          required
          width="md"
          disabled
        />
        <ProFormList
          name="reciptInfo"
          initialValue={[
            {
              reciptor: '0x47c54368624a341FaC52F0901213e84E5ad6079B',
              point: 250
            },
            {
              reciptor: props.values.creatorAddress,
              point: 500
            }
          ]}
          label={'recipients'}
          max={2}
          deleteIconProps={false}
        >
          <ProFormGroup key="group">
            <ProFormText width={'lg'} name="reciptor" label="reciptor" disabled />
            <ProFormDigit name="point" label="point" disabled />
          </ProFormGroup>
        </ProFormList>
        <ProFormDateTimePicker name="mintTime" label={'mintTime'} required width="md" disabled />
      </StepsForm.StepForm>
    </StepsForm>
  )
}

export default BuildForm
