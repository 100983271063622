import { ActionType, ProColumns, ProDescriptionsItemProps } from '@ant-design/pro-components'
import { FooterToolbar, PageContainer, ProDescriptions, ProTable } from '@ant-design/pro-components'
import { useWeb3React } from '@web3-react/core'
import { Drawer, message } from 'antd'
import { SupportedChainId } from 'constants/chains'
import { BigNumber } from 'ethers/lib/ethers'
import { parseEther } from 'ethers/lib/utils'
import { useSafeMintCallback } from 'hooks/useControllerContractCallback'
import moment from 'moment'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  useApproveProjectMutation,
  useBuildProjectMutation,
  useGetBuildInfoMutation,
  useGetDownloadPresignMutation,
  useGetProjectsMutation,
  useUpdateProjectMutation
} from 'state/daos/slice'
import { useIsTransactionConfirmed, useIsTransactionPending, useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled from 'styled-components/macro'
import { ExternalLink } from 'theme'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'

import BuildForm from './components/BuildForm'
import type { FormValueType } from './components/UpdateForm'
import UpdateForm from './components/UpdateForm'

const StyledInLink = styled.span`
  text-decoration: none;
  cursor: pointer;
  color: #2172e5;

  :hover {
    text-decoration: underline;
  }

  :focus {
    outline: none;
    text-decoration: underline;
  }

  :active {
    text-decoration: none;
  }
`

const Dashboard: React.FC = () => {
  /**
   * @en-US Pop-up window of new window
   * @zh-CN 新建窗口的弹窗
   *  */
  const [createModalVisible, handleModalVisible] = useState<boolean>(false)
  /**
   * @en-US The pop-up window of the distribution update window
   * @zh-CN 分布更新窗口的弹窗
   * */
  const [updateModalVisible, handleUpdateModalVisible] = useState<boolean>(false)

  const [showDetail, setShowDetail] = useState<boolean>(false)

  const actionRef = useRef<ActionType>()
  const [currentRow, setCurrentRow] = useState<API.RuleListItem>()
  const [selectedRowsState, setSelectedRows] = useState<API.RuleListItem[]>([])

  const { mintCallback } = useSafeMintCallback()

  const { account, chainId } = useWeb3React()
  const [getProjects] = useGetProjectsMutation()
  const [updateProject] = useUpdateProjectMutation()
  const [approveProject] = useApproveProjectMutation()
  const [buildProject] = useBuildProjectMutation()
  const [getBuildInfo] = useGetBuildInfoMutation()
  const [getDownloadPresign] = useGetDownloadPresignMutation()

  const [currentBuildInfo, setCurrentBuildInfo] = useState({} as any)
  const isCurrentPending = useIsTransactionPending(currentBuildInfo?.trxId)
  const isCurrentConfirm = useIsTransactionConfirmed(currentBuildInfo?.trxId)
  const addTransaction = useTransactionAdder()

  useEffect(() => {
    console.log('isCurrentPending', isCurrentConfirm, isCurrentPending, currentBuildInfo)
    if (currentBuildInfo?.trxId && !isCurrentPending && !isCurrentConfirm) {
      try {
        addTransaction(currentBuildInfo?.buildInfo.transationResponse, {
          type: TransactionType.BUILD,
          projectId: currentBuildInfo?.projectId,
          projectIndex: currentBuildInfo?.trxId,
          launchTime: parseInt(currentBuildInfo?.buildInfo?.mintTime.toString())
        })
      } catch (error) {
        console.error(error)
      }
    }
  }, [
    addTransaction,
    currentBuildInfo,
    currentBuildInfo?.buildInfo?.mintTime,
    currentBuildInfo?.buildInfo?.transationResponse,
    currentBuildInfo.launchTime,
    currentBuildInfo?.projectId,
    currentBuildInfo?.trxId,
    isCurrentConfirm,
    isCurrentPending
  ])

  const handleDownload = useCallback(
    (url: string, projectId: number) => {
      getDownloadPresign({ projectId })
        .then((data: any) => {
          console.log(data.data)
          if (data.data && data.data.code === 0 && data.data.data.preSignUrl) window.open(data.data.data.preSignUrl)
          else message.error('Get presign url error.')
        })
        .catch(() => {
          message.error('Get presign error.')
        })
    },
    [getDownloadPresign]
  )

  const handleUpdate = async ({ fields }: { fields: FormValueType }) => {
    const hide = message.loading('Configuring')
    console.log(fields)
    if (fields && fields.id && fields.status) {
      try {
        if (parseInt(fields.status.toString()) === 3) {
          if (fields?.rejectId) {
            await updateProject({
              projectId: fields.id,
              status: parseInt(fields.status.toString()),
              rejectId: parseInt(fields?.rejectId?.toString())
            })
          } else {
            message.error('params rejectId error')
            return false
          }
        } else if (parseInt(fields.status.toString()) === 2) {
          if (fields.price && fields.mintTime) {
            await approveProject({
              projectId: fields.id,
              status: parseInt(fields.status.toString()),
              price: fields.price.toString(),
              launchTime: Math.round(new Date(fields.mintTime).getTime() / 1000)
            })
          } else {
            message.error('params error')
            return false
          }
        } else {
          message.error('status error')
          return false
        }
        hide()

        message.success('Configuration is successful')
        return true
      } catch (error) {
        hide()
        message.error('Configuration failed, please try again!')
        return false
      }
    } else {
      message.error('Configuration error')
      return false
    }
  }
  const handleBuild = async (fields: API.RuleListItem) => {
    const hide = message.loading('Building')
    console.log('handleBuild', fields)
    if (fields && fields?.collectionName && account && fields?.maxSupply && fields?.maxSupply > 0) {
      try {
        const res = fields.reciptInfo.reduce((item, v) => {
          Object.keys(v).forEach((key) => {
            const value = v[key]
            if (item[key]) item[key].push(value)
            else item[key] = [value]
          })
          return item
        }, {})

        const transationResponse = await mintCallback(
          fields?.id,
          fields?.collectionName,
          fields?.collectionName,
          fields?.baseurl + '/',
          fields?.creator,
          fields?.creatorAddress,
          fields?.description,
          'https://www.niftylicense.org/',
          fields?.engine,
          res.reciptor,
          res.point,
          BigNumber.from(fields.maxSupply),
          parseEther(fields.price.toString()),
          Math.round(new Date(fields.mintTime).getTime() / 1000)
        )

        const params = {
          projectId: fields?.id,
          name: fields?.collectionName,
          symbol: fields?.collectionName,
          baseTokenURI: fields?.baseurl + '/',
          creator: fields?.creator,
          creatorAddress: fields?.creatorAddress,
          description: fields?.description,
          license: 'https://www.niftylicense.org/',
          engine: fields?.engine,
          recipients: res.reciptor,
          basisPoints: res.point,
          maxSupply: BigNumber.from(fields.maxSupply),
          price: parseEther(fields.price.toString()),
          mintTime: Math.round(new Date(fields.mintTime).getTime() / 1000),
          transationResponse
        }
        await buildProject({
          projectId: fields.id,
          buildInfo: params,
          projectIndex: transationResponse?.hash,
          trxId: transationResponse?.hash
        })
        hide()
        message.success('Builded successfully')
        return true
      } catch (error) {
        hide()
        console.log(error)
        message.error('Building failed, please try again!')
        return false
      }
    } else {
      hide()
      if (fields.maxSupply <= 0) {
        message.error('Check maxsupply must more than 0!')
      } else {
        message.error('Building failed, please try again!')
      }
      return false
    }
  }

  const columns: ProColumns<API.RuleListItem>[] = [
    {
      title: 'id',
      dataIndex: 'id',
      tip: "The project's unique key",
      hideInSearch: true,
      rowSpan: 1,
      // eslint-disable-next-line react/display-name
      render: (dom, entity) => {
        return (
          <StyledInLink
            onClick={async () => {
              setCurrentRow(entity)
              setShowDetail(true)
            }}
          >
            {dom}
          </StyledInLink>
        )
      }
    },
    {
      title: 'Collection Name',
      dataIndex: 'collectionName',
      sorter: true,
      rowSpan: 1,
      hideInSearch: true
    },
    {
      title: 'Creator',
      dataIndex: 'creator',
      sorter: true,
      hideInSearch: true
    },
    {
      title: 'Engine',
      dataIndex: 'engine',
      sorter: true,
      hideInSearch: true
    },
    {
      title: 'Description',
      dataIndex: 'description',
      valueType: 'textarea',
      rowSpan: 1,
      hideInSearch: true,
      hideInTable: true
    },

    {
      title: 'Email',
      dataIndex: 'email',
      sorter: true,
      hideInSearch: true
    },
    {
      title: 'SocialLink',
      dataIndex: 'socialLink',
      sorter: true,
      hideInSearch: true
    },
    {
      title: 'Website',
      dataIndex: 'website',
      sorter: true,
      hideInSearch: true,
      // eslint-disable-next-line react/display-name
      render: (_, record) => (
        <ExternalLink key="website" href={record.website}>
          {record.website}
        </ExternalLink>
      )
    },
    {
      title: 'Baseurl',
      dataIndex: 'baseurl',
      sorter: true,
      hideInSearch: true,
      // eslint-disable-next-line react/display-name
      render: (_, record) => (
        <ExternalLink key="baseurl" href={record.baseurl}>
          {record.baseurl}
        </ExternalLink>
      )
    },

    {
      title: 'trxId',
      dataIndex: 'trxId',
      sorter: true,
      hideInSearch: true,
      hideInTable: true,
      // eslint-disable-next-line react/display-name
      render: (_, record) => (
        <ExternalLink
          key="trxId"
          href={getExplorerLink(chainId ?? SupportedChainId.MAINNET, record.trxId, ExplorerDataType.TRANSACTION)}
        >
          View on Explorer
        </ExternalLink>
      )
    },
    // {
    //   title: 'projectIndex',
    //   dataIndex: 'projectIndex',
    //   sorter: true,
    //   hideInSearch: true,
    //   hideInTable: true
    // },
    {
      title: 'buildInfoDetail',
      dataIndex: 'buildInfoDetail',
      sorter: true,
      hideInSearch: true,
      hideInTable: true,
      valueType: 'code',
      // eslint-disable-next-line react/display-name
      render: (_, record) => JSON.stringify(record.buildInfo)
    },
    {
      title: 'launchTime',
      dataIndex: 'launchTime',
      sorter: true,
      hideInSearch: true,
      hideInTable: true,
      renderText: (text) => {
        console.log(text)
        return moment(text * 1000)
          .format('DD MMM yyyy, HH:mm [GMT]ZZ')
          .slice(0, -2)
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      valueEnum: {
        0: {
          text: 'waiting',
          status: 'Waitting'
        },
        // 1: {
        //   text: 'fixing',
        //   status: 'Fixing'
        // },
        2: {
          text: 'approved',
          status: 'Approved'
        },
        3: {
          text: 'rejected',
          status: 'Rejected'
        },
        4: {
          text: 'deployed',
          status: 'Deployed'
        },
        5: {
          text: 'building',
          status: 'Building'
        },
        6: {
          text: 'builded failed',
          status: 'Builded Failed'
        }
      }
    },
    {
      title: 'Operating',
      dataIndex: 'option',
      valueType: 'option',
      render: (_, record) => {
        if (record.status === 6) {
          return [
            <StyledInLink
              key="config"
              onClick={() => {
                handleModalVisible(true)
                setCurrentRow(record)
              }}
            >
              Rebuild
            </StyledInLink>,
            <StyledInLink
              key="zip"
              onClick={() => {
                handleDownload(record.compressedUrl, record.id)
              }}
            >
              zip
            </StyledInLink>
          ]
        }
        if (record.status === 2) {
          return [
            <StyledInLink
              key="config"
              onClick={() => {
                handleModalVisible(true)
                setCurrentRow(record)
              }}
            >
              Build
            </StyledInLink>,
            <StyledInLink
              key="zip"
              onClick={() => {
                handleDownload(record.compressedUrl, record.id)
              }}
            >
              zip
            </StyledInLink>
          ]
        }

        if (record.status === 0) {
          return [
            <StyledInLink
              key="config"
              onClick={() => {
                handleUpdateModalVisible(true)
                setCurrentRow(record)
              }}
            >
              Actions
            </StyledInLink>,
            <StyledInLink
              key="zip"
              onClick={() => {
                handleDownload(record.compressedUrl, record.id)
              }}
            >
              zip
            </StyledInLink>
          ]
        }
        return [
          <StyledInLink
            key="zip"
            onClick={() => {
              handleDownload(record.compressedUrl, record.id)
            }}
          >
            zip
          </StyledInLink>
        ]
      }
    }
  ]

  return (
    <PageContainer>
      <ProTable<API.RuleListItem, API.PageParams>
        headerTitle={'Enquiry form'}
        actionRef={actionRef}
        rowKey="id"
        scroll={{ y: 'auto' }}
        search={{
          labelWidth: 'auto'
        }}
        toolBarRender={() => []}
        request={async (params) => {
          const ret: any = await getProjects({
            page: params.current ? params.current - 1 : 0,
            pageSize: params.pageSize,
            status: params.status ?? -1
          })

          if (ret.data.data) {
            return ret.data.data
          }
        }}
        columns={columns}
        rowSelection={{
          onChange: (_, selectedRows) => {
            setSelectedRows(selectedRows)
          }
        }}
      />
      {selectedRowsState?.length > 0 && (
        <FooterToolbar
          extra={
            <div>
              Chosen <span style={{ fontWeight: 600 }}>{selectedRowsState.length}</span> 项 &nbsp;&nbsp;
              {/* <span>
                Total number of service calls
                {selectedRowsState.reduce((pre, item) => pre + item?.callNo!, 0)} 万
              </span> */}
            </div>
          }
        />
      )}
      <BuildForm
        onSubmit={async (value) => {
          const success = await handleBuild(value as API.RuleListItem)
          if (success) {
            handleModalVisible(false)
            setCurrentRow(undefined)
            if (actionRef.current) {
              actionRef.current.reload()
            }
          }
        }}
        onCancel={() => {
          handleModalVisible(false)
          if (!showDetail) {
            setCurrentRow(undefined)
          }
        }}
        modalVisible={createModalVisible}
        values={currentRow || {}}
      />
      <UpdateForm
        onSubmit={async (value) => {
          const success = await handleUpdate({
            fields: value
          })
          if (success) {
            handleUpdateModalVisible(false)
            setCurrentRow(undefined)
            if (actionRef.current) {
              actionRef.current.reload()
            }
          }
        }}
        onCancel={() => {
          handleUpdateModalVisible(false)
          if (!showDetail) {
            setCurrentRow(undefined)
          }
        }}
        updateModalVisible={updateModalVisible}
        values={currentRow || {}}
      />

      <Drawer
        width={600}
        visible={showDetail}
        onClose={() => {
          setCurrentRow(undefined)
          setShowDetail(false)
        }}
        closable={false}
      >
        {currentRow?.collectionName && (
          <ProDescriptions<API.RuleListItem>
            column={2}
            title={currentRow?.collectionName}
            request={async () => {
              try {
                const ret: any = await getBuildInfo({ projectId: currentRow.id })
                if (ret && ret?.data.code === 0) {
                  console.log(ret.data.data)
                  if (currentRow.status === 5 && ret.data.data.trxId) {
                    console.log(ret.data.data.buildInfo)
                    setCurrentBuildInfo(ret.data.data)
                  }
                  return { data: { ...ret.data.data, ...currentRow } || {} }
                }
              } catch (error) {}
              return { data: currentRow || {} }
            }}
            params={{
              id: currentRow?.id
            }}
            columns={columns as ProDescriptionsItemProps<API.RuleListItem>[]}
          />
        )}
      </Drawer>
    </PageContainer>
  )
}

export default Dashboard
