import ethereumLogoUrl from 'assets/images/ethereum-logo.png'
import { SupportedChainId, SupportedL1ChainId } from 'constants/chains'

export enum NetworkType {
  L1,
  L2
}

interface BaseChainInfo {
  readonly networkType: NetworkType
  readonly blockWaitMsBeforeWarning?: number
  readonly docs: string
  readonly bridge?: string
  readonly explorer: string
  readonly infoLink: string
  readonly logoUrl: string
  readonly label: string
  readonly color: string
  readonly helpCenterUrl?: string
  readonly nativeCurrency: {
    name: string // e.g. 'Goerli ETH',
    symbol: string // e.g. 'gorETH',
    decimals: number // e.g. 18,
  }
}

export interface L1ChainInfo extends BaseChainInfo {
  readonly networkType: NetworkType.L1
}

export interface L2ChainInfo extends BaseChainInfo {
  readonly networkType: NetworkType.L2
  readonly bridge: string
  readonly statusPage?: string
  readonly defaultListUrl: string
}

export type ChainInfoMap = { readonly [chainId: number]: L1ChainInfo } & {
  readonly [chainId in SupportedL1ChainId]: L1ChainInfo
}

interface BaseOpenseaInfo {
  readonly tokenUrl?: string
  readonly apiUrl?: string
  readonly collectionUrl?: string
}
export type OpenseaInfoMap = { readonly [chainId: number]: BaseOpenseaInfo }
export const OPENSEA_INFO: OpenseaInfoMap = {
  [SupportedChainId.MAINNET]: {
    tokenUrl: 'https://opensea.io/assets/ethereum',
    apiUrl: 'https://api.opensea.io/api/v1',
    collectionUrl: 'https://opensea.io/collection'
  },
  [SupportedChainId.RINKEBY]: {
    tokenUrl: 'https://testnets.opensea.io/assets/rinkeby',
    apiUrl: 'https://testnets-api.opensea.io/api/v1',
    collectionUrl: 'https://testnets.opensea.io/collection'
  }
}

export const CHAIN_INFO: ChainInfoMap = {
  [SupportedChainId.MAINNET]: {
    networkType: NetworkType.L1,
    docs: 'https://docs.aw3c.org/',
    explorer: 'https://etherscan.io/',
    infoLink: 'https://info.aw3c.org/#/',
    label: 'Ethereum',
    color: 'green',
    logoUrl: ethereumLogoUrl,
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 }
  },
  [SupportedChainId.RINKEBY]: {
    networkType: NetworkType.L1,
    docs: 'https://docs.aw3c.org/',
    explorer: 'https://rinkeby.etherscan.io/',
    infoLink: 'https://info.aw3c.org/#/',
    label: 'Rinkeby',
    color: 'yellow',
    logoUrl: ethereumLogoUrl,
    nativeCurrency: { name: 'Rinkeby Ether', symbol: 'rETH', decimals: 18 }
  }
}
