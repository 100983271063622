import '@reach/dialog/styles.css'
import 'inter-ui'
import 'polyfills'
import 'components/Analytics'

import Blocklist from 'components/Blocklist'
import Web3Provider from 'components/Web3Provider'
import { LanguageProvider } from 'i18n'
import { BlockNumberProvider } from 'lib/hooks/useBlockNumber'
import { MulticallUpdater } from 'lib/state/multicall'
import App from 'pages/App'
import { StrictMode } from 'react'
import { Provider as BusProvider } from 'react-bus'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import * as serviceWorkerRegistration from 'serviceWorkerRegistration'
import store from 'state'
import ApplicationUpdater from 'state/application/updater'
import LogsUpdater from 'state/logs/updater'
import TransactionUpdater from 'state/transactions/updater'
import UserUpdater from 'state/user/updater'
import ThemeProvider, { ThemedGlobalStyle } from 'theme'
import RadialGradientByChainUpdater from 'theme/RadialGradientByChainUpdater'

if (!!window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false
}

function Updaters() {
  return (
    <>
      <RadialGradientByChainUpdater />
      <UserUpdater />
      <ApplicationUpdater />
      <TransactionUpdater />
      <MulticallUpdater />
      <LogsUpdater />
    </>
  )
}

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <LanguageProvider>
          <Web3Provider>
            <Blocklist>
              <BusProvider>
                <BlockNumberProvider>
                  <Updaters />
                  <ThemeProvider>
                    <ThemedGlobalStyle />
                    <App />
                  </ThemeProvider>
                </BlockNumberProvider>
              </BusProvider>
            </Blocklist>
          </Web3Provider>
        </LanguageProvider>
      </BrowserRouter>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
)

if (process.env.REACT_APP_SERVICE_WORKER !== 'false') {
  serviceWorkerRegistration.register()
}
